.signatureCanvas {
  border: 1px solid #c2deeb;
  border-radius: 12px;
  width: 100%;
  height: 96px;
}

#header-bar {
  opacity: 0;
}
