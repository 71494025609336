input {
  border: none;
  -webkit-appearance: none;
  outline: none;
  margin-left: 10px;
  padding: 10px 0 10px 10px;
  border-left: 1px solid #c2deeb;
  box-sizing: border-box;
}

.PhoneInputCountry {
  margin: 0 0 0 10px;
  box-sizing: border-box;
}
